import * as React from "react";

const NotFoundPage = () => {
  React.useEffect(() => {
    window.location.href = "/";
  }, []);

  return null;
};

export default NotFoundPage;
